import React, { useState } from "react"
// import { useTranslation } from "react-i18next"
import AudioPlayer from "react-h5-audio-player"

import {
  makeStyles,
  Container,
  List,
  ListItem,
  Paper,
  // IconButton,
} from "@material-ui/core"

// import GetAppIcon from "@material-ui/icons/GetApp"

const useStyles = makeStyles(theme => ({
  mainContainer: {
    padding: "2.5rem 0 4rem",
  },
  playList: {
    overflow: "hidden scroll",
    maxHeight: 350,
    "& li": {
      paddingTop: 12,
      paddingBottom: 12,
    },
    "& li:hover": {
      backgroundColor: "#f0f8ff",
      cursor: "pointer",
    },
  },
  playingNow: {
    backgroundColor: "#f0f8ff",
  },
  audiosList: {
    width: "100%",
  },
  downloadBtn: {
    position: "absolute",
    right: 8,
  },
  listNumber: {
    marginRight: 6
  }
}))

// const playlist = [
//   {
//     name: "枝芽",
//     src: "https://hanzluo.s3-us-west-1.amazonaws.com/music/zhiya.mp3",
//   },
//   {
//     name: "自由女神",
//     src: "https://hanzluo.s3-us-west-1.amazonaws.com/music/ziyounvshen.mp3",
//   },
//   {
//     name: "无雨无晴",
//     src: "https://hanzluo.s3-us-west-1.amazonaws.com/music/wuyuwuqing.mp3",
//   },
//   {
//     name: "碎片",
//     src: "https://hanzluo.s3-us-west-1.amazonaws.com/music/suipian.mp3",
//   },
//   {
//     name: "永恒的港湾",
//     src:
//       "https://hanzluo.s3-us-west-1.amazonaws.com/music/yonghengdegangwan.mp3",
//   },
// ]

const AudioPlayList = ({ playlist }) => {
  const classes = useStyles()
  // const {t, i18n} = useTranslation()
  const [currentMusicIndex, setCurrentMusicIndex] = useState(0)

  const handleClickPrevious = () => {
    setCurrentMusicIndex(
      currentMusicIndex === 0 ? playlist.length - 1 : currentMusicIndex - 1
    )
  }
  const handleClickNext = () => {
    setCurrentMusicIndex(
      currentMusicIndex < playlist.length - 1 ? currentMusicIndex + 1 : 0
    )
  }
  const handlePlayThis = itemNumber => {
    setCurrentMusicIndex(itemNumber)
  }

  return (
    <>
      <Container className={classes.mainContainer}>
        <AudioPlayer
          autoPlayAfterSrcChange={true}
          showSkipControls={true}
          showJumpControls={false}
          showdownload={true}
          src={playlist[currentMusicIndex]}
          onClickPrevious={handleClickPrevious}
          onClickNext={handleClickNext}
        />
        <Paper className={classes.playList}>
          <List>
            {playlist.map((item, index) => (
              <ListItem
                key={index}
                onClick={() => handlePlayThis(index)}
                className={`${
                  index === currentMusicIndex ? classes.playingNow : ""
                } ${classes.audiosList}`}
              >
                <b className={classes.listNumber}>{index + 1} - </b>{item.replace(/^.*[\\\/]/, '').split('.').slice(0, -1).join('.')}
                {/* <a
                  href={item.src}
                  target="_blank"
                  rel="noreferrer"
                  download="Best audio"
                  className={classes.downloadBtn}
                >
                  <IconButton color="primary" aria-label="Best audio">
                    <GetAppIcon />
                  </IconButton>
                </a> */}
              </ListItem>
            ))}
          </List>
        </Paper>
      </Container>
    </>
  )
}
export default AudioPlayList
