import React, { useState, useEffect } from "react"
import axios from "axios"
import { useTranslation } from "react-i18next"

import {
  makeStyles,
  Container,
  Paper,
  Grid,
  Typography,
  Button,
} from "@material-ui/core"

import SEO from "../components/seo"
import AudioPlayList from "../components/audioPlayList"
import PageTitle from "../components/page-title"

import QueueMusicIcon from "@material-ui/icons/QueueMusic"
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt"
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace"

const windowGlobal = typeof window !== "undefined" && window
const documentGlobal = typeof document !== `undefined` && document

const useStyles = makeStyles(theme => ({
  mainContainer: {
    padding: "2.5rem 0 4rem",
  },
  playList: {
    textAlign: "center",
    padding: "20px 10px",
  },
}))

// const playlisttest = [
//   {
//     name: "枝芽",
//     src: "https://hanzluo.s3-us-west-1.amazonaws.com/music/zhiya.mp3",
//   },
//   {
//     name: "自由女神",
//     src: "https://hanzluo.s3-us-west-1.amazonaws.com/music/ziyounvshen.mp3",
//   },
//   {
//     name: "无雨无晴",
//     src: "https://hanzluo.s3-us-west-1.amazonaws.com/music/wuyuwuqing.mp3",
//   },
//   {
//     name: "碎片",
//     src: "https://hanzluo.s3-us-west-1.amazonaws.com/music/suipian.mp3",
//   },
//   {
//     name: "永恒的港湾",
//     src:
//       "https://hanzluo.s3-us-west-1.amazonaws.com/music/yonghengdegangwan.mp3",
//   },
// ]

const PlayList = ({ pageContext }) => {
  const classes = useStyles()
  const [t, i18n] = useTranslation()
  const [playListData, setPlayListData] = useState()
  const [listData, setListData] = useState()

  const { pLang } = pageContext

  useEffect(() => {
    i18n.on("languageChanged", () => {
      windowGlobal.history.pushState(
        pLang,
        "audios",
        `/${i18n.language}/audios`
      )
      if (windowGlobal.history.pushState) {
        windowGlobal.history.pushState(
          pLang,
          "audios",
          `/${i18n.language}/audios`
        )
      } else {
        documentGlobal.location.href = `/${i18n.language}/audios`
      }
    })
  }, [i18n, pLang])

  useEffect(() => {
    axios
      .get(`${process.env.GATSBY_API_URL}/playlists`)
      .then(res => setPlayListData(res.data))
  }, [])

  const listenToMusic = data => {
    setListData(JSON.parse(data))
  }

  const goBackTraking = () => {
    setListData()
  }

  return (
    <>
      <SEO title={t("breadcrumbs.audios")} lang={i18n.language} />
      <PageTitle title={t("breadcrumbs.audios")} />
      <Container className={classes.mainContainer}>
        {!listData ? (
          <Grid container spacing={3}>
            {playListData &&
              playListData.map(item => (
                <Grid item xs={12} sm={2} key={item.id}>
                  <Paper
                    className={classes.playList}
                    onClick={() => listenToMusic(item.files)}
                  >
                    <QueueMusicIcon style={{ fontSize: 52 }} />
                    <Typography>{item.title}</Typography>
                  </Paper>
                </Grid>
              ))}
          </Grid>
        ) : (
          <>
            <Button
              variant="outlined"
              primary=""
              startIcon={
                i18n.language === "ar" ? (
                  <ArrowRightAltIcon />
                ) : (
                  <KeyboardBackspaceIcon />
                )
              }
              onClick={goBackTraking}
            >
              {t("myProfile.goBack")}
            </Button>
            <AudioPlayList playlist={listData} />
          </>
        )}
      </Container>
    </>
  )
}
export default PlayList
